/* @import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&family=Signika:wght@400;500;600&family=Gloock&family=Playfair+Display:wght@600&family=Nunito:wght@500;700&family=Mynerve&family=Lora:wght@500&family=Labrada:wght@700&family=Dancing+Script:wght@700&family=Lobster&family=Secular+One&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&family=Signika:wght@400;500;600&display=swap');
@font-face {
  font-family: 'Jumper'; 
  src: url("./fonts/Jumper-Regular.ttf") format("opentype");
  font-style: normal;
}

ul {
  padding-left: 0px;
}

body {
  margin: 0;
  font-family: Roboto Slab, 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}