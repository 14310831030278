.carousel-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carousel-wrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.carousel-content-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.carousel-content {
    display: flex;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-content > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

.left-arrow, .right-arrow {
    /* position: absolute; */
    z-index: 1;
    /* top: 50%; */
    /* transform: translateY(-50%); */
    /* width: 40px; */
    height: 40px;
    border-radius: 24px;
    background-color: rgb(255 255 255 / 0.7);
    border: 1px solid #ddd;
    cursor: pointer;
    /* bottom: 0px; */
}

.left-arrow {
    left: -12px;
}

.right-arrow {
    right: -12px;
}