/* TODO: Make this inline, it belongs in the TaxonomyNavigation.js file under the styling object. I don't know how to make pseudo-selectors work inline in React. */
.scroll-overflow::-webkit-scrollbar {
  display: none;
}

.react-link {
  text-decoration: none;
  color: black;
}

button {
  border: none;
  border-radius: 4px;
  padding: 8px;
  margin: 4px;
  font-family: 'Roboto Slab', 'Roboto', 'Helvetica Neue', sans-serif;
}